<template>
    <div>
        <step-bar :active="3" @clickStep="clickStep"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>验收申请文件</span>
            </div>

            <div class="commonNotice">
                <div class="content">
                    <div>
                        <p>《竣工检验申请书》、《竣工报验单》、《竣工资料审核单》电子文件模板下载</p>
                        <el-button type=primary @click="handleDownload">下载模板</el-button>
                    </div>
                    <div class="uploader">
                        <ex-uploader v-model="completionApplyFiles" :singleFileMode='true'></ex-uploader>
                        <br/>
                        <span>竣工检验申请书</span>
                    </div>
                    <div class="uploader">
                        <ex-uploader v-model="completionAskCheckFiles" :singleFileMode='true'></ex-uploader>
                        <br/>
                        <span>竣工报验单</span>
                    </div>
                    <div class="uploader">
                        <ex-uploader v-model="completionCheckFiles" :singleFileMode='true'></ex-uploader>
                        <br/>
                        <span>竣工资料审核单</span>
                    </div>
                </div>
            </div>
        </el-card>

        <div class="submit-btn-group">
            <el-button @click="cancelHandler()">取消</el-button>
            <el-button type=primary @click="saveHandler(0)">保存</el-button>
            <el-button type="primary" @click="saveHandler(1)">保存并发起验收申请</el-button>
        </div>
    </div>

</template>

<script>
import StepBar from '@/components/StepBar/index';
import {TASK_STATUS} from '@/utils/constants';

export default {
    name: 'CompletionApply',
    components: {
        StepBar,
    },
    data() {
        return {
            completionApplyFiles: [],
            completionAskCheckFiles: [],
            completionCheckFiles: [],
            form: {
                id: null,
                applyId: null,
                completionFiles: [],
            },
        };
    },
    activated() {

    },
    computed: {},
    methods: {
        async init(row) {
            //初始化数据
            this.taskInfo = row;
            this.form.applyId = this.taskInfo.businessFormId;
            this.form.workflowTaskId = this.taskInfo.id;

            //获取历史数据
            if (this.taskInfo.currentStatus === TASK_STATUS.COMPLETION_APPLY_SAVED) {
                this.getCompletionApply();
            }

            //初始化地图
            this.$nextTick(() => {
                //this.$refs['ref_elec_map'].init(this.form.applyId);
            });
        },
        async handleDownload() {
            const link = document.createElement('a');
            link.href = `${this.$client.serverUrl}template/验收申请.doc?token=${this.$store.state.token}`;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 0);
        },
        //获取已经保存过的数据
        getCompletionApply() {
            this.$client.getCompletionApply(this.form.applyId).then((result) => {
                if (result && result.code === 0) {
                    //有数据，需要回显
                    this.form.id = result.data.id;
                    this.form.applyId = result.data.applyId;
                    //获取已经上传的文件信息
                    if (result.data.completionFiles && result.data.completionFiles.length > 0) {
                        for (let item of result.data.completionFiles) {
                            if (item.remark === '竣工检验申请书') {
                                this.completionApplyFiles = item.files;
                            } else if (item.remark === '竣工报验单') {
                                this.completionAskCheckFiles = item.files;
                            } else if (item.remark === '竣工资料审核单') {
                                this.completionCheckFiles = item.files;
                            }
                        }
                    }
                }
            });
        },
        saveHandler(formState) {
            const title = formState === 0 ? '确认保存？' : '确认提交？';
            this.$confirm(title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveData(formState);
            }).catch(e => {
            });
        },
        async saveData(formState) {
            //检查文件是否都上传了
            if (!(this.completionApplyFiles.length > 0 /*&& this.completionAskCheckFiles.length > 0 && this.completionCheckFiles.length > 0*/)) {
                this.$message.warning(`请先上传完整资料再提交`);
                return;
            }
            this.form.formState = formState;
            this.form.completionFiles = [];
            if (this.completionApplyFiles.length > 0) {this.form.completionFiles.push({remark: '竣工检验申请书', files: this.completionApplyFiles});}
            if (this.completionAskCheckFiles.length > 0) {this.form.completionFiles.push({remark: '竣工报验单', files: this.completionAskCheckFiles});}
            if (this.completionCheckFiles.length > 0) {this.form.completionFiles.push({remark: '竣工资料审核单', files: this.completionCheckFiles});}
            //保存数据
            this.$client.addCompletionApply(this.form).then((result) => {
                if (result && result.code === 0) {
                    this.$message.success('保存成功');
                    //保存成功关闭窗口
                    this.$emit('closePanel', 2);
                    this.loading = false;
                    this.closeElement();
                }
            });
        },
        cancelHandler() {
            this.$confirm('确认取消保存？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('closePanel', 2);
                this.closeElement();
            }).catch(e => {
            });
        },
        // 关闭窗体
        closeElement() {
            this.$emit('closePanel', 2);
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    created() {
    }
};
</script>

<style>
    .uploader {
        margin: 30px 20px 10px 20px;
        display: inline-block;
        text-align: center;
    }
</style>
